import {
  Heading,
  VStack,
  Text,
  Link,
  Button,
  Icon,
  Flex,
  Card,
  CardBody,
  HStack,
  Tooltip,
  Code,
} from '@chakra-ui/react';
import { mdiArrowLeftRight, mdiPlus, mdiTrashCanOutline } from '@mdi/js';
import ClaimRow from './claim-row';
import { useRef, useState } from 'react';
import MappingRemovalConfirmationModal from './mapping-removal-confirmation-modal';
import isNil from 'lodash/isNil';
import {
  AddSourceClaimHandler,
  AddTargetClaimHandler,
  Mapping,
  OnDeleteSourceRowHandler,
  OnDeleteTargetRowHandler,
  OnSourceInputChange,
  OnTargetInputChange,
  RemoveClaimMappingHandler,
} from './claim-mapping-types';
import { FormikErrors } from 'formik';

const ClaimsMapping = ({
  connectionId,
  mappings,
  errors,
  addClaimMappingHandler,
  removeClaimMappingHandler,
  addSourceClaimHandler,
  onDeleteSourceRowHandler,
  addTargetClaimHandler,
  onDeleteTargetRowHandler,
  onSourceInputChange,
  onTargetInputChange,
}: {
  connectionId: string | undefined;
  mappings: Mapping[];
  errors: FormikErrors<{
    mappings: Mapping[];
  }>;
  addClaimMappingHandler: () => void;
  removeClaimMappingHandler: ({
    indexToRemove,
  }: RemoveClaimMappingHandler) => void;
  addSourceClaimHandler: ({ mappingIndex }: AddSourceClaimHandler) => void;
  onDeleteSourceRowHandler: ({
    mappingIndex,
    sourceIndexToRemove,
  }: OnDeleteSourceRowHandler) => void;
  addTargetClaimHandler: ({ mappingIndex }: AddTargetClaimHandler) => void;
  onDeleteTargetRowHandler: ({
    mappingIndex,
    targetIndexToRemove,
  }: OnDeleteTargetRowHandler) => void;
  onSourceInputChange: ({
    mappingIndex,
    indexToChange,
    value,
    type,
  }: OnSourceInputChange) => void;
  onTargetInputChange: ({
    mappingIndex,
    indexToChange,
    value,
    type,
  }: OnTargetInputChange) => void;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
  const [mappingToBeDeleted, setMappingToBeDeleted] = useState<number | null>(
    null,
  );
  const minSourceClaimsLimit = 0;
  const maxSourceClaimsLimit = 20;
  const minTargetClaimsLimit = 1;
  const maxTargetClaimsLimit = 20;
  const maxMappingsLimit = 20;
  const nameHelperText = 'Use regex to define the value';

  const setForDeletion = (index: number) => {
    setShowRemoveModal(true);
    setMappingToBeDeleted(index);
  };

  const onCloseModal = () => {
    setShowRemoveModal(false);
    setMappingToBeDeleted(null);
  };

  const onRemove = () => {
    if (!isNil(mappingToBeDeleted)) {
      removeClaimMappingHandler({ indexToRemove: mappingToBeDeleted });
      onCloseModal();
    }
  };

  const handleScroll = () => {
    setTimeout(() => {
      if (containerRef.current) {
        containerRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        });
      }
    }, 100);
  };

  return (
    <>
      <VStack pb='10' spacing='6' alignItems='flex-start' ref={containerRef}>
        <Heading id='claims-mapping' size='lg'>
          Claims Mapping
        </Heading>
        <Text>
          Add claims mapping configurations for your connections. Add a number
          of source claims by filling the name of the claim and a regular
          expression to match the claim value, then add a number of target
          claims by adding the name and a value.
        </Text>
        <Text>
          After the claims mapping configuration is finished, copy the
          connection id {connectionId ? <Code>{connectionId}</Code> : ''}, and
          go to XMC to complete the configuration.
        </Text>
        <Link
          fontSize='sm'
          href='https://doc.sitecore.com/portal/en/developers/sitecore-cloud-portal/index-en.html?contextId=claims-mapping'
          isExternal
        >
          XMC configuration info
        </Link>
        {mappings.map((mapping, index) => {
          const error = errors.mappings
            ? (errors.mappings[index] as Mapping)
            : undefined;

          return (
            <Flex
              w='full'
              backgroundColor='chakra-subtle-bg'
              borderRadius='8'
              padding='6'
              flexDirection='column'
              key={`mapping-group-${index}`}
            >
              <HStack>
                <Flex w='full' justifyContent='flex-end'>
                  <Button
                    variant='ghostColorOnHover'
                    colorScheme='danger'
                    onClick={() => setForDeletion(index)}
                    leftIcon={
                      <Icon>
                        <path d={mdiTrashCanOutline} />
                      </Icon>
                    }
                  >
                    Remove mapping
                  </Button>
                </Flex>
              </HStack>
              <HStack pt='6'>
                <Flex
                  w='full'
                  direction={['column', 'column', 'column', 'row']}
                >
                  <Card variant='flat' flex='1'>
                    <CardBody key={`cardbody-mapping-group-${index}`}>
                      <VStack alignItems='flex-start' spacing={6}>
                        <Heading size='sm'>Source claims</Heading>
                        {Array.isArray(mapping.sourceClaims) &&
                          mapping.sourceClaims.map(
                            (sourceClaim, sourceClaimIndex) => (
                              <ClaimRow
                                nameProps={{
                                  value: sourceClaim.name,
                                  onChange: (e) =>
                                    onSourceInputChange({
                                      mappingIndex: index,
                                      indexToChange: sourceClaimIndex,
                                      value: e.target.value,
                                      type: 'name',
                                    }),
                                  isRequired: true,
                                  name: 'sourceClaims.name',
                                  key: `source-name-${index}-${sourceClaimIndex}`,
                                }}
                                helperText={nameHelperText}
                                nameErrors={
                                  error?.sourceClaims?.[sourceClaimIndex]?.name
                                }
                                valueProps={{
                                  value: sourceClaim.value,
                                  isRequired: false,
                                  name: 'sourceClaims.value',
                                  onChange: (e) =>
                                    onSourceInputChange({
                                      mappingIndex: index,
                                      indexToChange: sourceClaimIndex,
                                      value: e.target.value,
                                      type: 'value',
                                    }),
                                  key: `source-value-${index}-${sourceClaimIndex}`,
                                }}
                                valueErrors={
                                  error?.sourceClaims?.[sourceClaimIndex]?.value
                                }
                                onDeleteRowHandler={() =>
                                  onDeleteSourceRowHandler({
                                    mappingIndex: index,
                                    sourceIndexToRemove: sourceClaimIndex,
                                  })
                                }
                                isDeleteDisabled={
                                  mapping.targetClaims.length ===
                                  minSourceClaimsLimit
                                }
                              />
                            ),
                          )}
                        <Tooltip
                          label={
                            mapping.sourceClaims.length === maxSourceClaimsLimit
                              ? 'Source claims limit reached'
                              : ''
                          }
                          closeOnClick={false}
                        >
                          <Button
                            variant='ghost'
                            colorScheme='primary'
                            size='sm'
                            onClick={() =>
                              addSourceClaimHandler({ mappingIndex: index })
                            }
                            leftIcon={
                              <Icon>
                                <path d={mdiPlus} />
                              </Icon>
                            }
                            isDisabled={
                              mapping.sourceClaims.length ===
                              maxSourceClaimsLimit
                            }
                          >
                            Add source claim
                          </Button>
                        </Tooltip>
                      </VStack>
                    </CardBody>
                  </Card>
                  <Flex p='4' alignItems='center' justifyContent='center'>
                    <Icon
                      color='neutral'
                      transform={[
                        'rotate(90deg)',
                        'rotate(90deg)',
                        'rotate(90deg)',
                        'rotate(0deg)',
                      ]}
                    >
                      <path d={mdiArrowLeftRight} />
                    </Icon>
                  </Flex>
                  <Card variant='flat' flex='1'>
                    <CardBody>
                      <VStack alignItems='flex-start' spacing={6}>
                        <Heading size='sm'>Target claims</Heading>
                        {Array.isArray(mapping.targetClaims) &&
                          mapping.targetClaims.map(
                            (targetClaim, targetClaimIndex) => (
                              <ClaimRow
                                nameProps={{
                                  value: targetClaim.name,
                                  isRequired: true,
                                  name: 'targetClaim.name',
                                  onChange: (e) =>
                                    onTargetInputChange({
                                      mappingIndex: index,
                                      indexToChange: targetClaimIndex,
                                      value: e.target.value,
                                      type: 'name',
                                    }),
                                  key: `target-name-${index}-${targetClaimIndex}`,
                                }}
                                helperText={nameHelperText}
                                nameErrors={
                                  error?.targetClaims?.[targetClaimIndex]?.name
                                }
                                valueProps={{
                                  value: targetClaim.value,
                                  isRequired: true,
                                  name: 'targetClaim.value',
                                  onChange: (e) =>
                                    onTargetInputChange({
                                      mappingIndex: index,
                                      indexToChange: targetClaimIndex,
                                      value: e.target.value,
                                      type: 'value',
                                    }),
                                  key: `target-value-${index}-${targetClaimIndex}`,
                                }}
                                valueErrors={
                                  error?.targetClaims?.[targetClaimIndex]?.value
                                }
                                onDeleteRowHandler={() =>
                                  onDeleteTargetRowHandler({
                                    mappingIndex: index,
                                    targetIndexToRemove: targetClaimIndex,
                                  })
                                }
                                isDeleteDisabled={
                                  mapping.targetClaims.length ===
                                  minTargetClaimsLimit
                                }
                              />
                            ),
                          )}
                        <Tooltip
                          label={
                            mapping.targetClaims.length === maxTargetClaimsLimit
                              ? 'Target claims limit reched'
                              : ''
                          }
                          closeOnClick={false}
                        >
                          <Button
                            variant='ghost'
                            colorScheme='primary'
                            size='sm'
                            onClick={() =>
                              addTargetClaimHandler({ mappingIndex: index })
                            }
                            leftIcon={
                              <Icon>
                                <path d={mdiPlus} />
                              </Icon>
                            }
                            isDisabled={
                              mapping.targetClaims.length ===
                              maxTargetClaimsLimit
                            }
                          >
                            Add target claim
                          </Button>
                        </Tooltip>
                      </VStack>
                    </CardBody>
                  </Card>
                </Flex>
              </HStack>
            </Flex>
          );
        })}
        <Tooltip
          label={
            mappings.length === maxMappingsLimit
              ? 'Claims mapping limit reched'
              : ''
          }
          closeOnClick={false}
        >
          <Button
            alignSelf='start'
            variant='outline'
            colorScheme='primary'
            onClick={() => {
              addClaimMappingHandler();
              handleScroll();
            }}
            leftIcon={
              <Icon>
                <path d={mdiPlus} />
              </Icon>
            }
            isDisabled={mappings.length === maxMappingsLimit}
          >
            Add claims mapping
          </Button>
        </Tooltip>
      </VStack>
      <MappingRemovalConfirmationModal
        isOpen={showRemoveModal}
        onClose={onCloseModal}
        onRemove={onRemove}
      />
    </>
  );
};

export default ClaimsMapping;
